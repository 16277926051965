import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Citas', url: '/citas', icon: 'calendar' },
    { title: 'Expedientes', url: '/expedientes-list', icon: 'clipboard' },
    /* { title: 'Promociones', url: '/promociones', icon: 'pricetags' }, */
    { title: 'Historial', url: '/historial', icon: 'clipboard' },
    { title: 'Consultas', url: '/consultas', icon: 'card' },
    { title: 'Configuración', url: '/configuracion', icon: 'construct' },
  ];

  constructor() {}
}
