import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'citas',
    pathMatch: 'full'
  },
  {
    path: 'tabla/:id',
    loadChildren: () => import('./tabla/tabla.module').then( m => m.TablaPageModule)
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'citas',
    loadChildren: () => import('./citas/citas.module').then( m => m.CitasPageModule)
  },
  {
    path: 'expedientes-list',
    loadChildren: () => import('./expedientes-list/expedientes-list.module').then( m => m.ExpedientesListPageModule)
  },
  {
    path: 'expediente-details/:id',
    loadChildren: () => import('./expediente-details/expediente-details.module').then( m => m.ExpedienteDetailsPageModule)
  },
  {
    path: 'citas-two',
    loadChildren: () => import('./citas-two/citas-two.module').then( m => m.CitasTwoPageModule)
  },
  {
    path: 'promociones',
    loadChildren: () => import('./promociones/promociones.module').then( m => m.PromocionesPageModule)
  },
  {
    path: 'consultas',
    loadChildren: () => import('./consultas/consultas.module').then( m => m.ConsultasPageModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./configuracion/configuracion.module').then( m => m.ConfiguracionPageModule)
  },
  {
    path: 'tabla',
    loadChildren: () => import('./tabla/tabla.module').then( m => m.TablaPageModule)
  },
  {
    path: 'historial',
    loadChildren: () => import('./historial/historial.module').then( m => m.HistorialPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
